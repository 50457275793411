import React from "react"
import { Link } from "gatsby"
import Layout from "../../../components/layouts"
import BlogList from "../../../components/resources/blog/BlogList"
import SEO from '../../../components/common/SEO'


export default ({ data, pageContext }) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let firstBlog = data.bannerBlog.allBlogs.edges[0].node
  const blogDate = new Date(firstBlog.date_created)
  const blogDay = blogDate.getDate()
  const blogMonth = monthNames[blogDate.getMonth()]
  const blogYear = blogDate.getFullYear()
  const imageUrl =
    firstBlog.social_image != null ? firstBlog.social_image.url : ""

  let categoryString = ""
  if (firstBlog.categories.length > 0) {
    firstBlog.categories.forEach(category => {
      if (category.blog_category != null) {
        categoryString += category.blog_category.title[0].text
      }
    });
  }

  let blogList = data.blogList.allBlogs.edges;

  const seo = {
    title: "Workhub Blog | Free EHS & Compliance Management Software",
    description:
      "Keep up with the latest trends & important insights into workplace safety & culture with topics like: Incidents vs Accidents, Augmented Reality & Virtual Reality (VR) for your safety program, Behaviour-Based Safety Observations (BBSOs), Safety Inspections & much more.",
  }


  return (
    <Layout>
          <SEO {...seo}/>
      <section className="breadcrumb_area_two">
        <ul className="list-unstyled bubble">
          {/* <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li> */}
        </ul>
        <div className="breadcrumb_shap"></div>
        <div className="container">
          <div className="breadcrumb_content_two text-center">
            <h1 className="f_p f_700 f_size_50 w_color l_height50 mb_20">Workhub Blog</h1>

          </div>
        </div>
      </section>
      <BlogList
        blogs={blogList}
        recentBlogs={data.recentBlogs}
        categoryList={data.categoryList}
      />
    </Layout>
  )
}

export const BlogListQuery = graphql`
  query($after: String) {
    bannerBlog: prismic {
      allBlogs(first: 1, sortBy: date_created_DESC) {
        edges {
          node {
            categories {
              blog_category {
                ... on PRISMIC_Category {
                  title
                }
              }
            }
            author {
              ... on PRISMIC_Author {
                last_name
                first_name
              }
            }
            date_created
            social_image
            title
            _meta {
              uid
            }
          }
        }
      }
    }
    blogList: prismic {
      allBlogs(sortBy: date_created_DESC, first: 30, after: $after) {
        totalCount
        edges {
          node {
            _linkType
            title
            social_description
            date_created
            thumbnail
            _meta {
              uid
            }
            author {
              ... on PRISMIC_Author {
                first_name
                last_name
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
    recentBlogs: prismic {
      allBlogs(first: 3, sortBy: date_created_DESC) {
        edges {
          node {
            _meta {
              uid
            }
            title
            thumbnail
            date_created
          }
        }
      }
    }
    categoryList: prismic {
      allCategorys {
        edges {
          node {
            title
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`

import React from "react"
import { Link } from "gatsby"
const BlogList = data => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const categories = data.categoryList.allCategorys
  const recents = data.recentBlogs.allBlogs
  // console.log(data.blogs)
  let blogs = data.blogs
  let blogList = []
  if (blogs) {
    blogs.forEach((blog, index) => {
      const blogDate = new Date(blog.node.date_created)
      const blogDay = blogDate.getDate()
      const blogMonth = monthNames[blogDate.getMonth()]
      const blogDescription =
        blog.node.social_description.length > 130
          ? blog.node.social_description.substring(0, 130) + "..."
          : blog.node.social_description
      const imageUrl =
        blog.node.thumbnail != null ? blog.node.thumbnail.url : ""
      blogList.push(
        <div className="main-blog-flex" key={index}>
          <div className="blog_list_item blog_list_item_two">
            {/* <Link
              to={`/resources/blog/${blog.node._meta.uid}/`}
              className="post_date"
            >
              <h2>
                {blogDay}
                <span>{blogMonth}</span>
              </h2>
            </Link> */}
            <div className="blog_img">
              <Link to={`/resources/blog/${blog.node._meta.uid}/`}>
                <span
                  className="blog_img_span"
                  style={{ backgroundImage: `url(${imageUrl})` }}
                  alt={blog.node.thumbnail.alt}
                ></span>
              </Link>
            </div>
            <div className="blog_content">
              <Link to={`/resources/blog/${blog.node._meta.uid}/`}>
                <h5 className="blog_title">{blog.node.title[0].text}</h5>
              </Link>
              <p>{blogDescription}</p>
              <div className="post-info-bottom">
                <Link
                  to={`/resources/blog/${blog.node._meta.uid}/`}
                  className="learn_btn_two"
                >
                  Read More <i className="arrow_right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  // Recent posts
  let recentPosts = []
  if (recents != null) {
    const posts = recents.edges
    posts.forEach((post, index) => {
      const postDate = new Date(post.node.date_created)
      const postDay = postDate.getDate()
      const postMonth = monthNames[postDate.getMonth()]
      const postYear = postDate.getFullYear()
      const postImage =
        post.node.thumbnail != null ? post.node.thumbnail.url : ""
      recentPosts.push(
        <div className="media post_item" key={index}>
          <img src={postImage} alt={post.node.title[0].text + " Thumbnail"} width="110" height="71" />
          <div className="media-body">
            <Link to={`/resources/blog/${post.node._meta.uid}`}>
              <h3>{post.node.title[0].text}</h3>
            </Link>
            <a href="#" className="entry_post_info">
              {postMonth + " " + postDay + ", " + postYear}
            </a>
          </div>
        </div>
      )
    })
  }

  let categoryElements = []
  if (categories != null) {
    const categoryList = categories.edges
    categoryList.forEach(category => {
      categoryElements.push(<Link to="/">{category.node.title[0].text}</Link>)
    })
  }

  // console.log(data)
  return (
    <section className="blog_area_two sec_pad">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 blog_grid_info">
            <div className="main-blog-row">{blogList}</div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlogList
